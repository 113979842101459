import React, { useEffect, useState, useMemo } from "react";
import _ from "lodash";
import moment from "moment-timezone";

import useHttp from "../../hooks/useHttp";
import useLoading from "../../hooks/useLoading";
import useSnackbar from "../../hooks/useSnackbar";

import { makeData } from "../Table/dataTest";

import {
  Button,
  Chip,
  Divider,
  Grid,
  Stack,
  Typography,
  colors
} from "@mui/material";
import { Add } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";

import { fuzzyFilter, arraySort, enumFilter } from "../Table/misc";
import { ActionCell } from "../Table/controls";

import LocalTable from "../Table/LocalTable";
import DeleteDialog from "../Dialogs/DeleteDialog";
import CreateEditLocationDialog from "./CreateEditLocationDialog";
import { LightTooltip } from "../Dialogs/controls";

const Locations = () => {
  const theme = useTheme();
  const { get, post } = useHttp();
  const { loading, setLoading } = useLoading();
  const { openSnackbar } = useSnackbar();

  const [editingLocation, setEditingLocation] = useState();
  const [showEditLocationDialog, setShowEditLocationDialog] = useState(false);
  const [locationToDelete, setLocationToDelete] = useState();
  const [showDeleteLocationDialog, setShowDeleteLocationDialog] = useState(
    false
  );

  // const [data, setData] = useState(() => makeData(50, "location"));
  const [data, setData] = useState([]);
  const [bulkStationConfigs, setBulkStationConfigs] = useState([]);
  const [refetch, setRefetch] = useState(false);

  const getLocations = async () => {
    setLoading(true);
    const res = await get("/getLocations");
    const isSuccessful = res.status < 400;
    if (isSuccessful) {
      setData(res.data.locations);
    }
    setLoading(false);
  };

  const getbulkStationConfigs = async () => {
    setLoading(true);
    const res = await get("/getBulkStationConfig");
    const isSuccessful = res.status < 400;
    console.log("getbulkStationConfigs res", res);
    if (isSuccessful) {
      setBulkStationConfigs(res.data.bulkStationConfigs);
    }
    setLoading(false);
  };

  const getDataFromServer = async () => {
    await getLocations();
    await getbulkStationConfigs();
  };

  const handleCreateLocation = async newLocation => {
    setLoading(true);
    const res = await post("/createLocation", { newLocation });
    const isSuccessful = res.status < 400;
    if (isSuccessful) {
      setShowEditLocationDialog(false);
      setRefetch(prev => !prev);
    }

    openSnackbar({
      open: true,
      message: isSuccessful
        ? "Successfully created location"
        : "Failed to create location",
      variant: "alert",
      alert: {
        color: isSuccessful ? "success" : "error"
      },
      close: false
    });
    setLoading(false);
  };

  const handleEditLocation = async newLocation => {
    setLoading(true);
    const res = await post("/updateLocation", {
      newLocation,
      _id: editingLocation._id
    });
    const isSuccessful = res.status < 400;
    if (isSuccessful) {
      setShowEditLocationDialog(false);
      setRefetch(prev => !prev);
    }

    openSnackbar({
      open: true,
      message: isSuccessful
        ? "Successfully updated location details"
        : "Failed to update location details",
      variant: "alert",
      alert: {
        color: isSuccessful ? "success" : "error"
      },
      close: false
    });
    setLoading(false);
  };

  const handleDeleteLocation = async () => {
    setLoading(true);
    const res = await post("/deleteLocation", {
      _id: locationToDelete._id
    });
    const isSuccessful = res.status < 400;
    if (isSuccessful) {
      setShowDeleteLocationDialog(false);
      setRefetch(prev => !prev);
    }

    openSnackbar({
      open: true,
      message: isSuccessful
        ? "Successfully deleted location"
        : "Failed to delete location",
      variant: "alert",
      alert: {
        color: isSuccessful ? "success" : "error"
      },
      close: false
    });
    setLoading(false);
  };

  useEffect(() => {
    getDataFromServer();
  }, [refetch]);

  const columns = useMemo(() => {
    return [
      {
        header: "Actions",
        size: 175,
        enableHiding: false,
        sticky: "left",
        cell: ({ row }) => {
          const location = row.original;
          return ActionCell({
            row,
            theme,
            handleEdit: () => {
              setEditingLocation(location);
              setShowEditLocationDialog(true);
            },
            handleDelete: () => {
              setLocationToDelete(location);
              setShowDeleteLocationDialog(true);
            }
          });
        }
      },
      {
        header: "Name",
        size: 200,
        minSize: 175,
        enableHiding: false,
        accessorKey: "name",
        filterFn: "includesString"
      },
      {
        id: "isRetail",
        header: "Retail",
        size: 150,
        minSize: 140,
        accessorFn: row =>
          _.get(row, "isRetail", false) ? "Retail" : "Non-retail",
        isEnum: true,
        filterFn: enumFilter
      },
      {
        header: "Collector Code",
        size: 175,
        minSize: 150,
        accessorKey: "collectorCode",
        filterFn: "includesString"
      },
      {
        header: "Address",
        size: 200,
        minSize: 175,
        accessorKey: "address",
        filterFn: "includesString"
      },
      {
        header: "City",
        size: 150,
        minSize: 125,
        accessorKey: "city",
        filterFn: "includesString"
      },
      {
        header: "Province",
        size: 150,
        minSize: 125,
        accessorKey: "province",
        filterFn: "includesString"
      },
      {
        header: "Country",
        size: 150,
        minSize: 125,
        accessorKey: "country",
        filterFn: "includesString"
      },
      {
        header: "Contact",
        size: 200,
        minSize: 175,
        accessorKey: "contact",
        filterFn: "includesString"
      },
      {
        header: "Contact Phone",
        size: 200,
        minSize: 175,
        accessorKey: "contactPhone",
        filterFn: "includesString"
      },
      {
        header: "Contact Email",
        size: 200,
        minSize: 175,
        accessorKey: "contactEmail",
        filterFn: "includesString"
      },
      {
        id: "tags",
        header: "Tags",
        size: 300,
        minSize: 275,
        accessorFn: row => _.get(row, "tags", []).join(","),
        cell: ({ row }) => {
          const tags = _.get(row, "original.tags", []).sort();

          const visibleTags = _.slice(tags, 0, 4);
          const hiddenTags = _.slice(tags, 4);

          const tooltipTags = (
            <Stack
              direction="row"
              spacing={1}
              justifyContent="center"
              flexWrap="wrap"
              useFlexGap
              sx={{
                p: 1
              }}
            >
              {hiddenTags.map(tag => (
                <Chip label={tag} />
              ))}
            </Stack>
          );

          return (
            <Stack direction="row" spacing={1} flexWrap="wrap" useFlexGap>
              {visibleTags.map(tag => (
                <Chip label={tag} />
              ))}
              {!_.isEmpty(hiddenTags) &&
                (hiddenTags.length > 1 ? (
                  <LightTooltip title={tooltipTags}>
                    <Chip
                      variant="outlined"
                      label={`${hiddenTags.length} MORE`}
                      className="cursor-default"
                    />
                  </LightTooltip>
                ) : (
                  <Chip label={_.first(hiddenTags)} />
                ))}
            </Stack>
          );
        },
        sortingFn: (rowA, rowB) => arraySort(rowA, rowB, "tags"),
        filterFn: "includesString"
      }
    ];
  }, []);

  return (
    <>
      <Grid
        className="w-full overflow-hidden rounded-lg"
        style={{
          margin: theme.spacing(2),
          padding: theme.spacing(3),
          backgroundColor: colors.grey[100]
        }}
      >
        <Typography variant="h4">Locations</Typography>
        <Divider
          style={{
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(2)
          }}
        />
        <LocalTable
          name="locations-table"
          data={data}
          columns={columns}
          initColVisibility={{
            province: false,
            contactPhone: false,
            contactEmail: false
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              setEditingLocation();
              setShowEditLocationDialog(true);
            }}
            disabled={loading}
            sx={{ width: "max-content" }}
          >
            <Add style={{ marginRight: theme.spacing(1) }} />
            Create Location
          </Button>
        </LocalTable>
      </Grid>
      <DeleteDialog
        open={showDeleteLocationDialog}
        handleClose={() => setShowDeleteLocationDialog(false)}
        handleDelete={handleDeleteLocation}
        text={`Are you sure you want to delete location, ${_.get(
          locationToDelete,
          "name",
          ""
        )}?`}
      />
      <CreateEditLocationDialog
        open={showEditLocationDialog}
        location={editingLocation}
        bulkStationConfigs={bulkStationConfigs}
        setOpen={setShowEditLocationDialog}
        handleSubmit={
          editingLocation ? handleEditLocation : handleCreateLocation
        }
      />
    </>
  );
};

export default Locations;
